<template lang="">
  <div>
  <div class="p-grid">
    <div class="p-col">
      <ability-editor 
        v-model:name="name" 
        v-model:isActive="isActive" 
        v-model:description="description"
        v-model:tags="tags"
        v-model:classification="classification"
        v-model:apcost="apcost"
        v-model:manacost="manacost"
        v-model:altresource="altresource"
        v-model:altcost="altcost"
        v-model:range="range"
        v-model:rangevalue="rangevalue"
        v-model:target="target"
        v-model:duration="duration"
        v-model:resistance="resistance"
        v-model:createdAt="createdAt"
        v-model:lastModified="lastModified"
        v-model:uid="uid"
        :docid="routeDocId">
      </ability-editor>
    </div>
    <div class="p-col">
      <ability-view 
        :name="name"
        :isActive="isActive"
        :description="description"
        :tags="tags"
        :classification="classification"
        :apcost="apcost"
        :manacost="manacost"
        :altresource="altresource"
        :altcost="altcost"
        :range="range"
        :rangevalue="rangevalue"
        :target="target"
        :duration="duration"
        :resistance="resistance"
        :createdAt="createdAt"
        :lastModified="lastModified"
        :uid="uid"
        :docid="routeDocId">
      </ability-view>
    </div>
  </div>
  <button class="button" @click="addUpdate">Add/Update</button>
  </div>
</template>
<script>
import AbilityEditor from '@/components/AbilityEditor'
import AbilityView from '@/components/AbilityView'
import { mapGetters, mapActions } from 'vuex'
import router from '@/router'

export default {
  components: {
    AbilityEditor,
    AbilityView,
  },
  data() {
    return {
      docid:null,
      name:null,
      isActive:false,
      description:null,
      tags:null,
      classification:null,
      apcost:null,
      manacost:null,
      altresource:null,
      altcost:null,
      range:null,
      rangevalue:null,
      target:null,
      duration:null,
      resistance:null,

      createdAt:null,
      lastModified:null,
      uid:null,
    }
  },
  computed: {
    ...mapGetters([
      'getAbilityById',
      'user',
      'abilities',
    ]),
    routeDocId() {
      return this.$route.params.docid ? this.$route.params.docid : null;
    }
  },
  created() {
    if(!this.abilities) {
      this.fetchAbilities();
    }
    if(this.routeDocId) {
      const ability = this.getAbilityById(this.routeDocId);
      for(const key in ability) {
        if(ability[key]) {
          this[key] = ability[key];
        }
      }
    }    
  },
  methods: {
    ...mapActions([
      'addAbility',
      'updateAbility',
      'deleteAbilityById',
      'fetchAbilities'
    ]),
    addUpdate() {
      const newAbility = {
        name:this.name,
        isActive:this.isActive,
        description:this.description,
        tags:this.tags,
        classification:this.classification,
        apcost:this.apcost,
        manacost:this.manacost,
        altresource:this.altresource,
        altcost:this.altcost,
        range:this.range,
        rangevalue:this.rangevalue,
        target:this.target,
        duration:this.duration,
        resistance:this.resistance,

        createdAt: (this.routeDocId) ? this.createdAt : this.$store.getters.fbTimestamp,
        lastModified: this.$store.getters.fbTimestamp,
        uid: this.user.uid,
        docid: (this.routeDocId) ? this.routeDocId : null,
      }
      if(!newAbility.name) {
        alert("You need a name");
        return;
      }
      // If this isn't set, it's a new ability
      if(this.routeDocId) {
        this.updateAbility(newAbility);
      } else {
        this.addAbility(newAbility);
      }
      router.push({name:"Abilities"})

    }
  }
}

</script>
<style lang="">

</style>